import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// @mui
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Stack,
  Drawer,
  Switch,
  ListItemIcon,
  ListItem,
  ListItemText,
  Divider,
  Typography,
} from "@mui/material";
// hooks
import useResponsive from "../../../hooks/useResponsive";
import useCollapseDrawer from "../../../hooks/useCollapseDrawer";
// utils
import cssStyles from "../../../utils/cssStyles";
// config
import { NAVBAR } from "../../../config";
// components
import Scrollbar from "../../../components/Scrollbar";
import { NavSectionVertical } from "../../../components/nav-section";
//
import navConfig from "./NavConfig";
import NavbarAccount from "./NavbarAccount";
import CollapseButton from "./CollapseButton";
import logoMax from "../../../assets/logo-max.png";
import logoX from "../../../assets/logo-x.png";
import logoMaxLight from "../../../assets/logo-max-light.png";
import useSettings from "hooks/useSettings";
import Iconify from "components/Iconify";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default function NavbarVertical({
  isOpenSidebar,
  onCloseSidebar,
}: Props) {
  const theme = useTheme();

  const { pathname } = useLocation();
  const { themeMode, onToggleMode } = useSettings();

  const isDesktop = useResponsive("up", "lg");

  const gitHash = process.env.REACT_APP_GIT_SHA;
  const releaseVersion = process.env.REACT_APP_RELEASE_VERSION;

  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onToggleCollapse,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: "center" }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {!isCollapse ? (
            <LogoDefault src={themeMode === "dark" ? logoMaxLight : logoMax} />
          ) : (
            <LogoCondensed src={logoX} />
          )}
          {isDesktop && !isCollapse && (
            <CollapseButton
              onToggleCollapse={onToggleCollapse}
              collapseClick={collapseClick}
            />
          )}
        </Stack>

        <NavbarAccount isCollapse={isCollapse} />
      </Stack>

      <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} />
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ pb: 1 }}>
        {isCollapse ? (
          <Stack sx={{ p: 2, alignItems: "center", width: "100%" }}>
            <Iconify icon="ph:moon" width={22} height={22} />
          </Stack>
        ) : (
          <ListItem>
            <ListItemIcon>
              <Iconify icon="ph:moon" width={22} height={22} />
            </ListItemIcon>
            <ListItemText sx={{ whiteSpace: "nowrap" }} primary="Dark Mode" />
            <Switch
              edge="end"
              onChange={onToggleMode}
              checked={themeMode === "dark"}
            />
          </ListItem>
        )}
        {(releaseVersion || gitHash) && (
          <>
            <Divider sx={{ borderStyle: "dashed", mb: 1, mt: 1 }} />
            <Stack
              direction="row"
              spacing={4}
              sx={{
                pl: 2,
                alignItems: "center",
                color: "text.disabled",
                visibility: isCollapse ? "hidden" : "visible",
              }}
            >
              {releaseVersion && (
                <Stack direction="row" alignItems="center">
                  <Iconify
                    icon="mdi:tag-outline"
                    width={14}
                    height={14}
                    mr={1}
                  />
                  <Typography variant="caption">{releaseVersion}</Typography>
                </Stack>
              )}
              {gitHash && (
                <Stack direction="row" alignItems="center">
                  <Iconify
                    icon="mdi:source-commit"
                    width={14}
                    height={14}
                    mr={1}
                  />
                  <Typography variant="caption">{gitHash}</Typography>
                </Stack>
              )}
            </Stack>
          </>
        )}
      </Box>
      {/* {!isCollapse && <NavbarDocs />} */}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse
            ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
            : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: "absolute",
        }),
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: "dashed",
              bgcolor: "background.default",
              transition: (theme) =>
                theme.transitions.create("width", {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}

const LogoDefault = styled("img")`
  width: 116px;
  min-width: 116px;
  height: 32px;
`;

const LogoCondensed = styled("img")`
  width: 32px;
  height: 32px;
`;
