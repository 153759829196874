// icons
import { Icon, IconifyIcon } from "@iconify/react";
// @mui
import { Box, BoxProps, SxProps, SvgIcon, SvgIconProps } from "@mui/material";
// ----------------------------------------------------------------------

interface Props extends BoxProps {
  sx?: SxProps;
  icon: IconifyIcon | string;
}

export default function Iconify({ icon, sx, ...other }: Props) {
  return <Box component={Icon} icon={icon} sx={{ ...sx }} {...other} />;
}

export const MaxIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ color: "text.secondary" }}
      {...props}
    >
      <path
        opacity="0.5"
        d="M9.99569 13.376L12.1297 10L9.99559 6.6235L12.7089 2.34023C12.843 2.12843 13.0762 2.00003 13.3269 2H17.1973L12.1406 10L17.1973 18H13.3274C13.0768 18.0002 12.8436 17.8717 12.7099 17.6598L9.99569 13.376Z"
        fill="currentColor"
      />
      <path
        d="M6.67034 2H2.8L7.85667 10L2.8 18H6.66987C6.92061 18.0001 7.1539 17.8717 7.28788 17.6598L12.1297 10L7.28835 2.34023C7.15425 2.12843 6.92103 2.00003 6.67034 2Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
