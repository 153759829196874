// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  register: path(ROOTS_AUTH, "/register"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  verify: path(ROOTS_AUTH, "/verify"),
};

export const PATH_PAGE = {
  page403: "/403",
  page404: "/404",
  page500: "/500",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  artist_group: {
    root: path(ROOTS_DASHBOARD, "/artist_group"),
    list: path(ROOTS_DASHBOARD, "/artist_group/list"),
    newArtistGroup: path(ROOTS_DASHBOARD, "/artist_group/new"),
    requests: path(ROOTS_DASHBOARD, "/artist_group/requests"),
  },
  artist_analysis: {
    root: path(ROOTS_DASHBOARD, "/artist_analysis"),
    detail: (id: string) => path(ROOTS_DASHBOARD, `/artist_analysis/${id}`),
  },
  nextsong_event: {
    root: path(ROOTS_DASHBOARD, "/nextsong_event"),
    list: path(ROOTS_DASHBOARD, "/nextsong_event/list"),
    newNextsongEvent: path(ROOTS_DASHBOARD, "/nextsong_event/new"),
  },
  setlive_event: {
    root: path(ROOTS_DASHBOARD, "/setlive_event"),
    list: path(ROOTS_DASHBOARD, "/setlive_event/list"),
    newSetLiveEvent: path(ROOTS_DASHBOARD, "/setlive_event/new"),
  },
  setlive_branding: {
    root: path(ROOTS_DASHBOARD, "/setlive_branding"),
    list: path(ROOTS_DASHBOARD, "/setlive_branding/list"),
    newSetLiveBranding: path(ROOTS_DASHBOARD, "/setlive_branding/new"),
  },
  setlive_rules: {
    root: path(ROOTS_DASHBOARD, "/setlive_rules"),
    create: path(ROOTS_DASHBOARD, "/setlive_rules/create"),
    edit: (slug: string) =>
      path(ROOTS_DASHBOARD, `/setlive_rules/edit/${slug}`),
  },
  setlive_optout: {
    root: path(ROOTS_DASHBOARD, "/setlive_optout"),
  },
  setlive_multiday: {
    root: path(ROOTS_DASHBOARD, "/setlive_multiday"),
  },
  sl_event: {
    root: path(ROOTS_DASHBOARD, "/sl"),
    list: path(ROOTS_DASHBOARD, "/sl/list"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/sl/${id}/edit`),
    duplicate: (id: string) => path(ROOTS_DASHBOARD, `/sl/${id}/duplicate`),
    newSetLiveEvent: path(ROOTS_DASHBOARD, "/sl/new"),
  },
  surveys: {
    root: path(ROOTS_DASHBOARD, "/surveys"),
    new: path(ROOTS_DASHBOARD, "/surveys/new"),
    edit: path(ROOTS_DASHBOARD, "/surveys/edit"),
  },
  reports: {
    root: path(ROOTS_DASHBOARD, "/reports"),
    signups: path(ROOTS_DASHBOARD, "/reports/signups"),
    additions: path(ROOTS_DASHBOARD, "/reports/artist-additions"),
    duplicates: path(ROOTS_DASHBOARD, "/reports/duplicate-events"),
  },
  home: {
    root: path(ROOTS_DASHBOARD, "/home"),
  },
};

export const PATH_DOCS = "https://docs-minimals.vercel.app/introduction";
