// @mui
import { styled } from "@mui/material/styles";
import { Box, AppBar, Toolbar } from "@mui/material";
// hooks
import useOffSetTop from "../../../hooks/useOffSetTop";
import useResponsive from "../../../hooks/useResponsive";
// utils
import cssStyles from "../../../utils/cssStyles";
// config
import { HEADER, NAVBAR } from "../../../config";
// components
import Iconify from "../../../components/Iconify";
import { IconButtonAnimate } from "../../../components/animate";
//
import logoMax from "../../../assets/logo-max.png";
import logoMaxLight from "../../../assets/logo-max-light.png";
import useSettings from "hooks/useSettings";

// ----------------------------------------------------------------------

type StickyHeaderProps = {
  isCollapse?: boolean;
  isOffset?: boolean;
  verticalLayout?: boolean;
};

export const StickyHeader = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== "isCollapse" && prop !== "isOffset" && prop !== "verticalLayout",
})<StickyHeaderProps>(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  borderBottom: "1px dashed #919eab3d",
  boxShadow: "none",
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(["width", "height"], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up("lg")]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: "100%",
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  onOpenSidebar: VoidFunction;
  isCollapse?: boolean;
  verticalLayout?: boolean;
};

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}: Props) {
  const isOffset =
    useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const { themeMode } = useSettings();
  const isDesktop = useResponsive("up", "lg");
  if (isDesktop) {
    return null;
  }
  return (
    <StickyHeader
      isCollapse={isCollapse}
      isOffset={isOffset}
      verticalLayout={verticalLayout}
    >
      <Toolbar
        sx={{
          minHeight: "100% !important",
          px: { lg: 5 },
        }}
      >
        <IconButtonAnimate
          onClick={onOpenSidebar}
          sx={{ mr: 1, color: "text.primary" }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButtonAnimate>
        <LogoDefault src={themeMode === "dark" ? logoMaxLight : logoMax} />
        <Box sx={{ flexGrow: 1 }} />

        {/* <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          <LanguagePopover />
          <NotificationsPopover />
          <ContactsPopover />
          <AccountPopover />
        </Stack> */}
      </Toolbar>
    </StickyHeader>
  );
}

const LogoDefault = styled("img")`
  width: 87px;
  height: 24px;
`;
