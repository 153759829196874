import { ReactNode, createContext, useState, useContext } from "react";
import { FilterProps } from "sections/@dashboard/setlive_event/list/SetLiveEventListHead";

interface TableContextProps {
  enabledFilters: FilterProps[];
  setEnabledFilters: React.Dispatch<React.SetStateAction<FilterProps[]>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  order: "asc" | "desc";
  setOrder: React.Dispatch<React.SetStateAction<"asc" | "desc">>;
  orderBy: string;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  filterName: string;
  setFilterName: React.Dispatch<React.SetStateAction<string>>;
  rowsPerPage: number;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  searchResult: { [key: string]: any };
  setSearchResult: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  rows: unknown[];
  setRows: React.Dispatch<React.SetStateAction<unknown[]>>;
}

const TableContext = createContext<TableContextProps>({} as TableContextProps);

interface TableProviderProps {
  children: ReactNode;
}

const TableProvider = ({ children }: TableProviderProps) => {
  const [searchResult, setSearchResult] = useState<{ [key: string]: any }>({});
  const [rows, setRows] = useState<unknown[]>([]);
  const [enabledFilters, setEnabledFilters] = useState<FilterProps[]>([]);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [orderBy, setOrderBy] = useState<string>("startsAt");
  const [filterName, setFilterName] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);

  const value = {
    enabledFilters,
    setEnabledFilters,
    page,
    setPage,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    filterName,
    setFilterName,
    rowsPerPage,
    setRowsPerPage,
    searchResult,
    setSearchResult,
    rows,
    setRows,
  };
  return (
    <TableContext.Provider value={value}>{children}</TableContext.Provider>
  );
};

export { TableProvider, TableContext };

export const useTableContext = () => useContext(TableContext);
