import { Link as RouterLink } from "react-router-dom";
// @mui
import { useTheme } from "@mui/material/styles";
import { Box, BoxProps } from "@mui/material";

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const theme = useTheme();

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg
        width="116"
        height="32"
        viewBox="0 0 116 32"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient
            x1="76.856%"
            y1="24.96%"
            x2="20.112%"
            y2="106.629%"
            id="id-54g1rd2ja__a"
          >
            <stop stopColor="#E71E3D" stopOpacity=".5" offset="11%" />
            <stop stopColor="#C81A46" stopOpacity=".5" offset="17%" />
            <stop stopColor="#8A1057" stopOpacity=".5" offset="31%" />
            <stop stopColor="#590965" stopOpacity=".5" offset="47%" />
            <stop stopColor="#36046E" stopOpacity=".5" offset="62%" />
            <stop stopColor="#220174" stopOpacity=".5" offset="80%" />
            <stop stopColor="#1B0076" stopOpacity=".5" offset="100%" />
          </linearGradient>
          <linearGradient
            x1="77.394%"
            y1="7.514%"
            x2="21.463%"
            y2="71.87%"
            id="id-54g1rd2ja__b"
          >
            <stop stopColor="#E71E3D" offset="0%" />
            <stop stopColor="#C61946" offset="7%" />
            <stop stopColor="#991353" offset="19%" />
            <stop stopColor="#720D5E" offset="31%" />
            <stop stopColor="#530866" offset="43%" />
            <stop stopColor="#3A056D" offset="55%" />
            <stop stopColor="#290272" offset="69%" />
            <stop stopColor="#1E0075" offset="83%" />
            <stop stopColor="#1B0076" offset="100%" />
          </linearGradient>
        </defs>
        <g fillRule="nonzero" stroke="none" strokeWidth="1" fill="none">
          <path
            d="M33.9218879.0207481149C30.3538965.0148840963 26.9213255 1.38642465 24.339823 3.84943953L23.9490265 4.22041298 23.559174 3.84943953C19.5948327.0894130358 13.8000148-1.00940695 8.73439528 1.03834808L7.95469027 1.34985251 7.95469027 1.34985251C7.95469027.628330767 7.36978133.0434218289 6.64825959.0434218289L0 .0434218289 0 31.9820649 7.95469027 31.9820649 7.95469027 14.0856637C7.95469027 10.7959882 10.5581121 8.04436578 13.8468437 7.97828909 15.4597389 7.94866343 17.0169158 8.56825942 18.1685494 9.69788275 19.320183 10.8275061 19.9697091 12.3724381 19.9712094 13.9856047L19.9712094 31.9839528 27.9268437 31.9839528 27.9268437 14.0847198C27.9268437 10.7950442 30.5293215 8.04342183 33.8180531 7.97828909 35.4307863 7.94891967 36.9877182 8.56862921 38.1391457 9.69822453 39.2905733 10.8278199 39.9399701 12.3726048 39.9414749 13.9856047L39.9414749 31.9839528 47.8971091 31.9839528 47.8971091 13.9856047C47.8895575 6.29427729 41.619823.0292625369 33.9218879.0207481149ZM76.199646.0198230088C74.5313877.0198230088 73.1789971 1.37221362 73.1789971 3.04047198 71.0079056 1.15067847 67.8513274.0273746313 64.3775811.0273746313 55.4345723.0273746313 48.9430088 6.74548673 48.9430088 16.0018879 48.9430088 20.4884956 50.4269027 24.5446608 53.1218879 27.4237168 55.8687906 30.35941 59.7890265 31.9764012 64.1661357 31.9764012 67.7446608 31.9764012 70.979587 30.7492625 73.1856047 28.6829499L73.1856047 28.6829499C73.1856046 30.4337312 74.6046169 31.8531661 76.3553982 31.8536873L80.8967552 31.8536873 80.8967552.0198230088 76.199646.0198230088ZM70.7313274 22.0271386C69.3078466 23.5374631 67.299115 24.3738053 65.0751622 24.3738053 60.5054867 24.3738053 57.1893805 20.8302065 57.1893805 15.9480826 57.1893805 11.0659587 60.5073746 7.52235988 65.0751622 7.52235988 69.7184661 7.52235988 72.960944 10.9876106 72.960944 15.9480826 72.960944 18.340059 72.1689676 20.4988791 70.7313274 22.0271386Z"
            fill="#000"
          />
          <path
            d="M110.71056,0 L103.005074,0 C102.506919,0.000735735664 102.043833,0.25650162 101.777935,0.677758112 L92.139233,15.9263717 L101.776047,31.1768732 C102.041945,31.5981296 102.505031,31.8538955 103.003186,31.8546313 L110.707729,31.8546313 L100.643304,15.9273156 L110.71056,0 Z"
            fill="url(#id-54g1rd2ja__a)"
          />
          <path
            d="M89.7774631,0 L82.0710324,0 L92.139233,15.9310914 L82.0710324,31.8584071 L89.7755752,31.8584071 C90.27373,31.8576713 90.7368159,31.6019055 91.0027139,31.180649 L100.64236,15.9310914 L91.0074336,0.681533923 C90.7415199,0.258385417 90.2772266,0.00111776494 89.7774631,0 L89.7774631,0 Z"
            fill="url(#id-54g1rd2ja__b)"
          />
          <path
            fill="#000"
            d="M113.479174 29.9233038 112.825959 29.9233038 112.825959 31.9716814 112.466313 31.9716814 112.466313 29.9233038 111.813097 29.9233038 111.813097 29.600472 113.479174 29.600472zM115.981593 31.9716814 115.621947 31.9716814 115.621947 30.3858407 115.075398 31.5421829 114.809204 31.5421829 114.248496 30.3858407 114.248496 31.9716814 113.88885 31.9716814 113.88885 29.599528 114.248496 29.599528 114.942301 31.0749263 115.621947 29.599528 115.981593 29.599528z"
          />
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
