// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import RtlLayout from "./components/RtlLayout";
import { ChartStyle } from "./components/chart";
import ScrollToTop from "./components/ScrollToTop";
import { ProgressBarStyle } from "./components/ProgressBar";
import NotistackProvider from "./components/NotistackProvider";
import ThemeColorPresets from "./components/ThemeColorPresets";
import ThemeLocalization from "./components/ThemeLocalization";
import MotionLazyContainer from "./components/animate/MotionLazyContainer";
import { DialogProvider } from "contexts/DialogContext";
import { TableProvider } from "contexts/TableContext";

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <DialogProvider>
                <TableProvider>
                  <MotionLazyContainer>
                    <ProgressBarStyle />
                    <ChartStyle />
                    <ScrollToTop />
                    <Router />
                  </MotionLazyContainer>
                </TableProvider>
              </DialogProvider>
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
