import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export interface Props {
  open?: boolean;
  title: string;
  content: string;
  onConfirm: () => void;
  onCancel: () => void;
  setOpen?: (value: boolean) => void;
}

export default function ConfirmationDialog({
  open,
  title,
  content,
  onConfirm,
  onCancel,
}: Props) {
  return (
    <Dialog
      open={open === undefined ? false : open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>No</Button>
        <Button onClick={onConfirm} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
