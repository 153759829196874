import * as React from "react";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { doc, getFirestore } from "firebase/firestore";
import { useUser } from "Auth";
import useAuth from "hooks/useAuth";
import { PATH_PAGE } from "routes/paths";

const AuthGateCommunity: React.FC = ({ children }) => {
  const { user, claims } = useUser();
  const { app } = useAuth();
  const [userDoc, loading] = useDocumentDataOnce(
    doc(getFirestore(app), `community_users/${user?.uid}`),
  );

  if (user === null) {
    const url = new URL(
      `/user/login-sso?redirect=${window.location.href}`,
      `https://${process.env.REACT_APP_FIREBASE_AUTHDOMAIN}`,
    );

    console.log(`Redirecting to: ${url.toString()}`);

    window.location.href = url.toString();
    return null;
  }

  if (loading) {
    return <>Loading...</>;
  }

  if (!userDoc && !claims?.admin) {
    window.location.href = PATH_PAGE.page403;
    return null;
  }

  return <>{children}</>;
};

export default AuthGateCommunity;
