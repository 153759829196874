import React, { createContext, useContext } from "react";
import { initializeApp, getApps } from "firebase/app";
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";
import type {
  CollectionReference,
  DocumentReference,
} from "firebase/firestore";
import {
  getFirestore,
  getDoc,
  getDocs,
  updateDoc,
  collection,
  writeBatch,
} from "firebase/firestore";
import { DateTime } from "luxon";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

const app = initializeApp(config);
const functions = getFunctions(app);

export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(
    process.env.REACT_APP_FIREBASE_RECAPTCHAKEY as string
  ),
  isTokenAutoRefreshEnabled: true,
});

if (
  process.env.NODE_ENV === "development" &&
  process.env.REACT_APP_USE_EMULATOR === "true"
) {
  connectFunctionsEmulator(functions, "localhost", 5001);
}

const callable = async (
  name: string,
  data: { [key: string]: any },
  region?: string
) => {
  let f = functions;
  if (region) {
    f = getFunctions(app, region);
  }
  const func = httpsCallable(f, name);
  try {
    return await func(data);
  } catch (error) {
    console.log(error);
    return { error };
  }
};

export const deleteCollection = async (ref: CollectionReference) => {
  try {
    const db = getFirestore(app);
    const docs = await getDocs(ref);
    if (docs.docs.length) {
      const batch = writeBatch(db);
      for (const doc of docs.docs) {
        batch.delete(doc.ref);
      }
      await batch.commit();
    }
  } catch (err) {
    console.log("Error deleting from", ref.path, err.message);
  }
};

export const resetEvent = async (ref: DocumentReference) => {
  if (process.env.REACT_APP_ENV === "production") return;
  const event = await getDoc(ref);
  await updateDoc(event.ref, {
    status: "published",
    deletedAt: null,
    endedAt: null,
    startsAt: DateTime.utc().toJSDate(),
  });
  await deleteCollection(collection(event.ref, "payments"));
  await deleteCollection(collection(event.ref, "requests"));
  await deleteCollection(collection(event.ref, "entries"));
  await deleteCollection(collection(event.ref, "users"));
  if (event.get("auction.enabled")) {
    await deleteCollection(collection(event.ref, "auction_bids"));
    await deleteCollection(collection(event.ref, "auction_winners"));
    // reset times for auction items to match new start of event
    const items = await getDocs(collection(event.ref, "auction_items"));
    for (const item of items.docs) {
      await updateDoc(item.ref, {
        processedAt: null,
        startAt: DateTime.utc().toJSDate(),
        endAt: DateTime.utc().plus({ minute: 30 }).toJSDate(),
        closedAt: null,
        canceledAt: null,
        updatedAt: DateTime.utc().toJSDate(),
      });
    }
  }
};

const userMock = {
  id: 123,
  email: "arbel@max.live",
  photoURL: "",
  displayName: "Display Name",
  role: "admin",
  phoneNumber: "310-310-3101",
  country: "",
  address: "",
  state: "",
  city: "",
  zipCode: "",
  about: "",
  isPublic: false,
};

const FirebaseContext = createContext({
  app,
  callable,
  isAuthenticated: true,
  isInitialized: true,
  register: (obj1: Object, obj2: Object, obj3: Object, obj4: Object) => {},
  login: (obj11: Object, obj2: Object) => {},
  logout: () => {},
  deleteCollection: (ref: CollectionReference) => {},
  resetEvent: (ref: DocumentReference) => {},
  method: "firebase",
  user: userMock,
});

const FirebaseProvider: React.FC = ({ children }) => (
  <FirebaseContext.Provider
    value={{
      app,
      callable,
      isAuthenticated: true,
      isInitialized: true,
      register: (obj1: Object, obj2: Object, obj3: Object, obj4: Object) => {},
      login: (obj11: Object, obj2: Object) => {},
      logout: () => {},
      deleteCollection,
      resetEvent,
      method: "firebase",
      user: userMock,
    }}
  >
    {getApps().length > 0 && children}
  </FirebaseContext.Provider>
);

export { FirebaseContext, FirebaseProvider };
export const useFirebaseContext = () => useContext(FirebaseContext);
