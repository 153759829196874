import ConfirmationDialog from "components/ConfirmDialog";
import { ReactNode, createContext, useState, useContext } from "react";

interface ConfirmDialogProps {
  title: string;
  content: string;
  onConfirm: () => void;
  open?: boolean;
}
interface DialogProps {
  confirm?: ConfirmDialogProps;
}

interface DialogContextProps {
  dialog: DialogProps;
  setDialog: React.Dispatch<React.SetStateAction<DialogProps>>;
}

const DialogContext = createContext<DialogContextProps>(
  {} as DialogContextProps
);

interface DialogProviderProps {
  children: ReactNode;
}

const DialogProvider = ({ children }: DialogProviderProps) => {
  const [dialog, setDialog] = useState<DialogProps>({});

  return (
    <DialogContext.Provider value={{ dialog, setDialog }}>
      {dialog.confirm && (
        <ConfirmationDialog
          {...dialog.confirm}
          onCancel={() => setDialog({})}
          onConfirm={() => {
            setDialog({});
            dialog.confirm?.onConfirm();
          }}
        />
      )}
      {children}
    </DialogContext.Provider>
  );
};

export { DialogProvider, DialogContext };

export const useDialogContext = () => useContext(DialogContext);
