import { styled as muiStyled } from "@mui/material/styles";
import { Box, Link, Typography } from "@mui/material";
import MyAvatar from "../../../components/MyAvatar";
import { useUser } from "../../../Auth";

// ----------------------------------------------------------------------

const RootStyle = muiStyled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

type Props = {
  isCollapse: boolean | undefined;
};

export default function NavbarAccount({ isCollapse }: Props) {
  const { user, signOut } = useUser();

  const doSignOut = () => {
    signOut("/403").catch(() => {});
  };

  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          bgcolor: "transparent",
        }),
      }}
    >
      <MyAvatar />

      <Box
        sx={{
          ml: 2,
          transition: (theme) =>
            theme.transitions.create("width", {
              duration: theme.transitions.duration.shorter,
            }),
          ...(isCollapse && {
            ml: 0,
            width: 0,
          }),
        }}
      >
        <Typography variant="subtitle2" noWrap>
          {user?.displayName}
        </Typography>
        <Link
          variant="body2"
          noWrap
          sx={{
            color: "text.secondary",
            cursor: "pointer",
            ...(isCollapse && {
              visibility: "none",
              opacity: 0,
            }),
          }}
          onClick={doSignOut}
        >
          sign out
        </Link>
      </Box>
    </RootStyle>
  );
}
