// hooks
import useAuth from "../hooks/useAuth";
// utils
import createAvatar from "../utils/createAvatar";
//
import Avatar, { Props as AvatarProps } from "./Avatar";

import { useUser } from "../Auth";

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: AvatarProps) {
  const { user } = useUser();

  return (
    <Avatar
      src={user?.photoURL || ""}
      alt={user?.displayName || ""}
      color={
        user?.photoURL
          ? "default"
          : createAvatar(user?.displayName || "MAX User").color
      }
      {...other}
    >
      {createAvatar(user?.displayName || "MAX User").name}
    </Avatar>
  );
}
