// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import SvgIconStyle from "../../../components/SvgIconStyle";
import Iconify from "../../../components/Iconify";
import { NavConfigProps } from "components/nav-section/type";
import { ReactComponent as NextSongLogo } from "assets/icon-nextsong.svg";

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  booking: getIcon("ic_booking"),
};

const navConfig: NavConfigProps = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "general",
    items: [
      // MANAGEMENT : Artist Groups
      {
        title: "Artist Groups",
        path: PATH_DASHBOARD.artist_group.root,
        icon: <Iconify icon="ion:people" />,
        children: [
          {
            title: "List",
            path: PATH_DASHBOARD.artist_group.list,
          },
          {
            title: "New",
            path: PATH_DASHBOARD.artist_group.newArtistGroup,
          },
          {
            title: "Requests",
            path: PATH_DASHBOARD.artist_group.requests,
          },
        ],
      },
      {
        title: "Reporting",
        path: PATH_DASHBOARD.reports.root,
        icon: <Iconify icon="ion:document-text" />,
        children: [
          {
            title: "New Signups",
            path: PATH_DASHBOARD.reports.signups,
          },
          {
            title: "Artist User Additions",
            path: PATH_DASHBOARD.reports.additions,
          },
          {
            title: "Duplicate Events",
            path: PATH_DASHBOARD.reports.duplicates,
          },
        ],
      },
      // {
      //   title: "Artist Analysis",
      //   path: PATH_DASHBOARD.artist_analysis.root,
      //   icon: ICONS.analytics,
      // },
    ],
  },
  // APPS
  // ----------------------------------------------------------------------
  {
    subheader: "Apps",
    items: [
      // MANAGEMENT : SET.Live
      {
        title: "SET.Live",
        path: PATH_DASHBOARD.sl_event.root,
        icon: <Iconify icon="ion:ticket" />,
        children: [
          {
            title: "Events",
            path: PATH_DASHBOARD.sl_event.list,
          },
          {
            title: "Rules",
            path: PATH_DASHBOARD.setlive_rules.root,
          },
          {
            title: "Opt-outs",
            path: PATH_DASHBOARD.setlive_optout.root,
          },
          {
            title: "Multi-day",
            path: PATH_DASHBOARD.setlive_multiday.root,
          },
        ],
      },
      // MANAGEMENT : SET.Fan
      {
        title: "SET.Fan",
        path: PATH_DASHBOARD.surveys.root,
        icon: <Iconify icon="fluent:clipboard-text-edit-24-filled" />,
        children: [
          {
            title: "Artist Survey",
            path: PATH_DASHBOARD.surveys.root,
          },
        ],
      },
      // MANAGEMENT : NextSong
      {
        title: "NextSong",
        path: PATH_DASHBOARD.nextsong_event.root,
        icon: <NextSongLogo />,
        children: [
          {
            title: "Events",
            path: PATH_DASHBOARD.nextsong_event.list,
          },
        ],
      },
    ],
  },
  {
    subheader: "Legacy",
    items: [
      // MANAGEMENT : SET.Live Legacy
      {
        title: "SL Events",
        path: PATH_DASHBOARD.setlive_event.root,
        icon: <Iconify icon="ion:ticket-outline" />,
      },
      {
        title: "SL Branding",
        path: PATH_DASHBOARD.setlive_branding.root,
        icon: <Iconify icon="ci:color" />,
      },
    ],
  },
];

export default navConfig;
