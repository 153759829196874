import { Suspense, lazy, ElementType } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// guards
import useAuth from "../hooks/useAuth";
import AuthGateCommunity from "AuthGateCommunity";
// layouts
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// guards
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from "../config";
// components
import LoadingScreen from "../components/LoadingScreen";

import { PATH_PAGE } from "./paths";

import { useUser } from "Auth";
import Home from "pages/Home";

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes("/dashboard") && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

const AuthGate: React.FC = ({ children }) => {
  const { isLoggedIn, claims } = useUser();

  if (!isLoggedIn) {
    const url = new URL(
      `/user/login?redirect=${window.location.href}`,
      `https://${process.env.REACT_APP_FIREBASE_AUTHDOMAIN}`,
    );

    window.location.href = url.toString();

    return null;
  }

  if (!claims?.admin) {
    window.location.href = PATH_PAGE.page403;
    return null;
  }

  return <>{children}</>;
};

export default function Router() {
  return useRoutes([
    // Dashboard Routes
    {
      path: "dashboard",
      element: (
        <>
          <AuthGate>
            <DashboardLayout />
          </AuthGate>
        </>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: "artist_group",
          children: [
            {
              element: <Navigate to="/dashboard/artist_group/list" replace />,
              index: true,
            },
            { path: "list", element: <ArtistGroupList /> },
            { path: "new", element: <ArtistGroupCreate /> },
            { path: ":id/edit", element: <ArtistGroupCreate /> },
            { path: "requests", element: <ArtistGroupRequests /> },
          ],
        },
        {
          path: "reports",
          children: [
            {
              element: <Navigate to="/dashboard/reports/signups" replace />,
              index: true,
            },
            { path: "signups", element: <ReportsSignups /> },
            { path: "duplicate-events", element: <ReportsDuplicateEvents /> },
            {
              path: "artist-additions",
              element: <ReportsArtistUserAdditions />,
            },
          ],
        },
        {
          path: "surveys",
          children: [
            { path: "", element: <SurveyList /> },
            { path: "new", element: <SurveyListCreate /> },
            { path: "edit/:id", element: <SurveyListCreate /> },
            { path: "edit/:id/:status", element: <SurveyListCreate /> },
          ],
        },
        {
          path: "nextsong_event",
          children: [
            {
              element: <Navigate to="/dashboard/nextsong_event/list" replace />,
              index: true,
            },
            { path: "list", element: <NextsongEventList /> },
            { path: "new", element: <NextsongEventCreate /> },
            { path: ":id/edit", element: <NextsongEventCreate /> },
            { path: ":id/duplicate", element: <NextsongEventCreate /> },
          ],
        },
        {
          path: "sl",
          children: [
            {
              element: <Navigate to="/dashboard/sl/list" replace />,
              index: true,
            },
            { path: "list", element: <SetLiveEventList /> },
            { path: "new", element: <SetLiveEventCreate /> },
            { path: ":id/edit", element: <SetLiveEventCreate /> },
            { path: ":id/duplicate", element: <SetLiveEventCreate /> },
          ],
        },
        {
          path: "setlive_branding",
          children: [
            {
              element: (
                <Navigate to="/dashboard/setlive_branding/list" replace />
              ),
              index: true,
            },
            { path: "list", element: <SetLiveBrandingList /> },
            { path: "new", element: <SetLiveBrandingCreate /> },
            { path: ":id/edit", element: <SetLiveBrandingCreate /> },
            { path: ":id/duplicate", element: <SetLiveBrandingCreate /> },
          ],
        },
        {
          path: "artist_analysis",
          children: [
            { path: "", element: <ArtistAnalysisList /> },
            { path: ":id", element: <ArtistAnalysisDetail /> },
          ],
        },
        {
          path: "setlive_rules",
          children: [
            { path: "", element: <SetLiveRulesList /> },
            { path: "create", element: <SetLiveRulesCreate /> },
            { path: "edit/:slug", element: <SetLiveRulesEdit /> },
          ],
        },
        {
          path: "setlive_optout",
          element: <SetLiveOptOut />,
        },
        {
          path: "setlive_multiday",
          element: <SetLiveMultiDay />,
        },
        // Legacy Set.Live
        {
          path: "setlive_event",
          children: [
            {
              element: <Navigate to="/dashboard/setlive_event/list" replace />,
              index: true,
            },
            { path: "list", element: <SetLiveEventListLegacy /> },
            { path: "new", element: <SetLiveEventCreateLegacy /> },
            { path: ":id/edit", element: <SetLiveEventCreateLegacy /> },
            { path: ":id/duplicate", element: <SetLiveEventCreateLegacy /> },
          ],
        },
        {
          path: "home",
          element: <Home />,
        },
      ],
    },
    {
      path: "/",
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
      index: true,
    },
    {
      path: "community/:artistGroupId",
      element: (
        <>
          <AuthGateCommunity>
            <ArtistIntegrationsLayout />
          </AuthGateCommunity>
        </>
      ),
    },
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "500", element: <Page500 /> },
        { path: "404", element: <Page404 /> },
        { path: "403", element: <Page403 /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
  ]);
}

// IMPORT COMPONENTS
const ArtistGroupList = Loadable(
  lazy(() => import("pages/dashboard/ArtistGroupList")),
);
const ArtistGroupCreate = Loadable(
  lazy(() => import("pages/dashboard/ArtistGroupCreate")),
);
const ArtistGroupRequests = Loadable(
  lazy(() => import("sections/@dashboard/artist_group/requests")),
);
const ReportsSignups = Loadable(
  lazy(() => import("sections/@dashboard/reports/Signups")),
);
const ReportsArtistUserAdditions = Loadable(
  lazy(() => import("sections/@dashboard/reports/ArtistUserAdditions")),
);
const ReportsDuplicateEvents = Loadable(
  lazy(() => import("sections/@dashboard/reports/DuplicateEvents")),
);
const ArtistAnalysisList = Loadable(
  lazy(() => import("pages/dashboard/ArtistAnalysisList")),
);
const ArtistAnalysisDetail = Loadable(
  lazy(() => import("pages/dashboard/ArtistAnalysisDetail")),
);
const NextsongEventList = Loadable(
  lazy(() => import("pages/dashboard/NextsongEventList")),
);
const NextsongEventCreate = Loadable(
  lazy(() => import("pages/dashboard/NextsongEventCreate")),
);
const SetLiveEventList = Loadable(
  lazy(() => import("pages/dashboard/SetLiveEvent/List")),
);
const SetLiveEventCreate = Loadable(
  lazy(() => import("pages/dashboard/SetLiveEvent/Create")),
);
const SetLiveBrandingList = Loadable(
  lazy(() => import("pages/dashboard/SetLiveBrandingList")),
);
const SetLiveBrandingCreate = Loadable(
  lazy(() => import("pages/dashboard/SetLiveBrandingCreate")),
);
const SetLiveRulesList = Loadable(
  lazy(() => import("pages/dashboard/SetLiveRulesList")),
);
const SetLiveRulesCreate = Loadable(
  lazy(() => import("pages/dashboard/SetLiveRulesCreate")),
);
const SetLiveRulesEdit = Loadable(
  lazy(() => import("pages/dashboard/SetLiveRulesEdit")),
);
const SetLiveOptOut = Loadable(
  lazy(() => import("pages/dashboard/SetLiveOptOut")),
);
const SetLiveMultiDay = Loadable(
  lazy(() => import("pages/dashboard/SetLiveMultiDay")),
);
const SurveyList = Loadable(lazy(() => import("pages/dashboard/SurveyList")));
const SurveyListCreate = Loadable(
  lazy(() => import("pages/dashboard/SurveyListCreate")),
);
const Page500 = Loadable(lazy(() => import("pages/Page500")));
const Page403 = Loadable(lazy(() => import("pages/Page403")));
const Page404 = Loadable(lazy(() => import("pages/Page404")));

// Legacy SET.Live

const SetLiveEventListLegacy = Loadable(
  lazy(() => import("pages/dashboard/SetLiveEventList")),
);
const SetLiveEventCreateLegacy = Loadable(
  lazy(() => import("pages/dashboard/SetLiveEventCreate")),
);
const ArtistIntegrationsLayout = Loadable(
  lazy(() => import("components/ArtistIntegrations")),
);
